"use strict";
import loadScript from "@elements/load-script";


export function initInScope ($scope) {
    let $paymentForms = $scope.find('.js-arvato-paynext');

    let $widgetContainers = $paymentForms.find('.js-arvato-paynext__apn-widget');
    if( $widgetContainers.length ) {
        $widgetContainers.each( function () {
            let $container = $(this);
            let $paymentForm = $container.closest('.js-arvato-paynext');
            let $errorContainer = $container.find('.js-arvato-paynext__errors');

            // https://test.arvato-payment.de/WidgetApi/sample.html
            let defaultApiOptions = {
                automaticParentResize: true,
                // frameWidth: 400,
                // frameHeight: 600
                clearFrameAfterSuccessfulPayment : true
            };
            let apiOptions = $container.data('widget-options');
            let effectiveOptions = {...defaultApiOptions, ...apiOptions};

            let tokenInputName = $container.data('success-target');
            // paymentForm[paymentToken]
            let $tokenInput = $paymentForm.find( 'input[name="' + tokenInputName + '"]');

            // let $tokenTriggerButton = $paymentForm.find( 'button[name="tokenize"]' );

            // https://test.arvato-payment.de/sample/Pmg.Site/Scripts/V2/WidgetApi.js
            let apiScriptUrl = $container.data('widget-script');
            loadScript(apiScriptUrl).then(() => {
                // build options
                let arvatoOptions = new Arvato.PayNext.WidgetApiOptions();
                for (const prop in effectiveOptions) {
                    arvatoOptions[prop] = effectiveOptions[prop];
                }

                let paymentToken = null;

                arvatoOptions.renderErrorCallback  = function( error ) {
                    // show error message
                    console.error( error );
                    // update error-container
                    $errorContainer.attr('hidden', false);
                    $errorContainer.html(error);
                };
                arvatoOptions.successCallback = function( response ) {
                    // forward token to hidden input
                    $tokenInput.val( response.PaymentToken );
                    paymentToken = response.PaymentToken;
                    $errorContainer.attr('hidden', true);
                    // submit form when token is ready
                    $paymentForm.trigger('forceSubmission');
                };
                arvatoOptions.errorCallback  = function( response ) {
                    // update error-container
                    $errorContainer.attr('hidden', false);
                    $errorContainer.html(response.Error.Message);
                };
                // arvatoOptions.onWidgetContentResized = function( response ) {
                //     /**
                //      * This parameter accepts a function that will be called when the widget content is resized.
                //      * Thus, the shop is able to de-scribe its own custom behavior for the WidgetApi resize event.
                //      * This function can override the automatic resize behavior since it will be executed after the default resize when the parameter ‘automaticParentResize’ is set to true.
                //      */
                // };

                let arv = new Arvato.PayNext.WidgetApi(arvatoOptions);

                const $collapseParent = $container.closest('.collapse');

                if ($collapseParent.length) {
                    /* wait until slide is shown if it is initially hidden in a collapse/accordion */
                    if ($collapseParent.hasClass('show')) {
                        arv.render();
                    } else {
                        $collapseParent.one('shown.bs.collapse', function () {
                            if (!$container.find('iframe').length) {
                                arv.render();
                            }
                        });
                    }
                }


                $paymentForm.data('arvato', arv);

                // $tokenTriggerButton.click( function() {
                //     //if( paymentToken === null ) {
                //         arv.tokenize();
                //     //}
                //     //$paymentForm.submit();
                // } );


                // attach to submit
                if( false ) {
                    $paymentForm.submit( function (event) {
                        // tokenize payment - permit reset
                        if (paymentToken === null) {
                            arv.tokenize();
                        }
                        // permit if token present
                        return paymentToken !== null;
                    } );
                }

            }).catch(console.error);

        })
    }
}

// todo: handle this stuff via promises, because the tokenization is async (waiting for the success-callback)
export function hasToken($form) {
    let tokenInputName = $form.find('.js-arvato-paynext__apn-widget').data('success-target');
    let arv = $form.data('arvato');
    let paymentToken = $form.find( 'input[name="' + tokenInputName + '"]').val();

    if (!paymentToken) {
        arv.tokenize();
    }
    // permit if token present
    return !!$form.find( 'input[name="' + tokenInputName + '"]').val();
}